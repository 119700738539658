/* eslint-disable import/no-cycle */

import React, { useCallback, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Toolbar } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import * as tourActions from 'actions/tours'
import * as uiActions from 'actions/ui'
import * as shipmentsAction from 'actions/shipments'
import * as warehouseAction from 'actions/warehouse'

import * as fromCombined from 'selectors/combined'
import * as fromUi from 'selectors/ui'
import * as fromWarehouse from 'selectors/warehouse'

import { ShipmentTour, Tour } from 'common/models/typings/Tour'
import { Collation } from 'common/models/typings/Collation'

import { useInterval } from 'hooks/useInterval'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'

import NoTours from 'common/components/features/EmptyState/NoTours'

import NotificationTour from 'common/components/features/TourPlanning/Notifications/Tour'
import AnimatedTourCard from 'common/components/features/TourCard'
import TourMap from 'common/components/features/TourMap'
import NotificationMap from 'common/components/features/TourMap/Notification'
import TourMapActions from 'common/components/features/TourMap/Actions'
import CreateToursDialog from 'common/components/features/CreateToursDialog'
import CreateToursDialogResult from 'common/components/features/CreateToursDialog/Results'
import DialogOverview from 'common/components/features/TourPlanning/Dialog/Overview'

import useStyles from './useStyles'
import { NETWORK_TYPE } from '../../../common/models/typings/TourOverview'
import * as fromNetworkType from '../../../selectors/networkType'
import NetworkNotReady from '../../../common/components/features/EmptyState/NetworkNotReady'
import { capitalize } from '../../../utils/capitalize'

const TourPlanningPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const networkType: NETWORK_TYPE = useSelector(fromNetworkType.getNetworkType)
  const currentNetworkReady = useSelector(fromWarehouse.getCurrentNetworkReady)

  const optimizeResult = useSelector((state: any) => state.ui.optimizeResult)
  const tours: ShipmentTour[] = useSelector(fromCombined.getShipmentTours)
  const locked: boolean = useSelector(fromCombined.areToursLocked)
  const selectedMarkers: ShipmentTour['id'][] = useSelector(
    fromUi.getSelectedCollations,
  )

  const [createDialog, setCreateDialog] = React.useState(false)
  const [overviewDialog, setOverviewDialog] = React.useState(false)
  const [creationCounter, setCreationCounter] = React.useState(0)

  const handleOpenCreateDialog = () => {
    setCreateDialog(true)
  }

  const handleCloseCreateDialog = () => {
    setCreateDialog(false)
  }

  const handleOpenOverviewDialog = () => {
    setOverviewDialog(true)
  }

  const handleCloseOverviewDialog = () => {
    setOverviewDialog(false)
  }

  const handleOptimizationReset = React.useCallback(() => {
    dispatch(uiActions.setOptimizeResponse(undefined))
  }, [dispatch])

  const handleOptimize = React.useCallback(async () => {
    // reload shipments to make sure they are included in optimization
    await dispatch(shipmentsAction.reloadShipments())

    // reset the optimization result
    handleOptimizationReset()

    // reopen the creating dialog loader
    setCreateDialog(true)

    // optimize
    await dispatch(shipmentsAction.assignToTours())

    // increment counter
    setCreationCounter((prev) => prev + 1)

    // close dialog
    setCreateDialog(false)
  }, [dispatch, handleOptimizationReset])

  const handleSaveTour = React.useCallback(
    (isLocked?: boolean) => {
      dispatch(tourActions.saveTours(isLocked, networkType))
      dispatch(tourActions.saveDrivers())

      // remove expanded tour card
      dispatch(tourActions.setSelectedTourId(null))
      // remove hidden tours
      dispatch(tourActions.setHiddenTourIds(0, true))
    },
    [dispatch, networkType],
  )

  return (
    <>
      {tours.length < 1 || !currentNetworkReady ? (
        <Box mt={10}>
          {!currentNetworkReady ? (
            <NetworkNotReady />
          ) : (
            <NoTours onPrimaryAction={handleOpenCreateDialog} />
          )}
        </Box>
      ) : (
        <>
          <Toolbar disableGutters>
            <Button onClick={() => handleSaveTour()} disabled={locked}>
              <Typography variant="button" transform="capitalize">
                {t('SAVE_PLANNING_BY_NETWORK', {
                  networkType: capitalize(t(`NETWORK_TYPE.${networkType}`)),
                })}
              </Typography>
            </Button>
            <Box width={16} />
            <Button
              color="white"
              startIcon={<Icon name="plus" fill="currentColor" />}
              onClick={handleOpenCreateDialog}
              disabled={locked}
            >
              <Typography variant="button" transform="capitalize">
                {t('CREATE_NEW_TOURS_BY_NETWORK', {
                  networkType: capitalize(t(`NETWORK_TYPE.${networkType}`)),
                })}
              </Typography>
            </Button>
            <Box flexGrow={1} display="flex" justifyContent="flex-end">
              <Button
                variant="flat"
                color="background"
                startIcon={<Icon name="table" />}
                onClick={handleOpenOverviewDialog}
              >
                <Typography variant="button" transform="capitalize">
                  {t('OVERVIEW')}
                </Typography>
              </Button>
            </Box>
          </Toolbar>
          <Grid container className={classes.gridContainer} spacing={4}>
            <Grid item xs={6} className={classes.toursContainer}>
              <NotificationTour />
              <AnimatedTourCard />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ position: 'relative', height: 784 }}>
                <NotificationMap />
                <TourMap />
                {selectedMarkers.length > 0 && <TourMapActions />}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <CreateToursDialog
        open={createDialog}
        onClose={handleCloseCreateDialog}
        onOptimize={handleOptimize}
      />
      <CreateToursDialogResult
        open={Boolean(optimizeResult?.visible)}
        onClose={handleOptimizationReset}
        onReOptimize={handleOptimize}
      />
      <DialogOverview
        open={overviewDialog}
        onClose={handleCloseOverviewDialog}
      />
    </>
  )
}

export default TourPlanningPage

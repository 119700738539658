/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoWindow, Marker } from '@react-google-maps/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import { useDispatch, useSelector } from 'react-redux'
import { moveSingle, setHiddenTourIds } from 'actions/tours'
import { clearSelected } from 'actions/ui'
import * as uiActions from 'actions/ui'

import { ShipmentTour, Tour } from 'common/models/typings/Tour'
import { COLORS } from 'constants/index'

import MarkerIcon from './MarkerIcon'

import * as fromCombined from 'selectors/combined'

import { translate } from 'utils'
import styles from './styles.module.scss'
import SvgMarker from '../../base/vectors/SvgMarker'
import { ButtonBase } from '@material-ui/core'

export interface IGoogleMarkerProps {
  tourColor: string
  stop: any
  tour: Tour
  isSelected: boolean
  hilightedTour?: number
  isSingleTour?: boolean
}

const GoogleMarker = React.memo(
  ({
    tourColor,
    stop,
    tour,
    isSelected,
    hilightedTour,
    isSingleTour,
  }: IGoogleMarkerProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [showMarker, setShowMarker] = React.useState(false)
    const [icon, setIcon] = React.useState<any>()
    const areToursLocked = useSelector(fromCombined.areToursLocked)
    const tours: ShipmentTour[] = useSelector(fromCombined.getShipmentTours)

    const handleMoveSingle = React.useCallback(
      (oldTour, newTour, collation) => () => {
        dispatch(moveSingle(oldTour.id, newTour.id, collation.id))
        dispatch(clearSelected())
        setShowMarker(false)
      },
      [dispatch],
    )

    const handleRightClick = React.useCallback(
      (stopId: number) => () => dispatch(uiActions.toggleSelected(stopId)),
      [dispatch],
    )

    const handleToggleMarker = React.useCallback(
      () => !areToursLocked && setShowMarker(!showMarker),
      [areToursLocked, showMarker],
    )

    const {
      recipient: { location },
      position,
    } = stop

    const opacity = React.useMemo(
      // eslint-disable-next-line no-nested-ternary
      () => (hilightedTour ? (hilightedTour === tour.id ? 1 : 0.3) : 1),
      [hilightedTour, tour.id],
    )

    React.useEffect(() => {
      const markerColor = isSelected
        ? COLORS.GREEN_1 // selected color
        : tourColor || 'cdcdcd'
      setIcon(MarkerIcon(position, markerColor, stop.isMultiColli))
    }, [isSelected, stop.isMultiColli, tourColor, position])

    const toggleTour = React.useCallback(() => {
      dispatch(setHiddenTourIds(tour.id))
    }, [dispatch, tour.id])

    return (
      <Marker
        key={stop.id}
        position={{
          lat: location.latitude,
          lng: location.longitude,
        }}
        title={`${location.address} ${location.postalCode} ${location.city}`}
        icon={icon}
        onClick={handleToggleMarker}
        onRightClick={handleRightClick(stop.id)}
        opacity={opacity}
      >
        {React.useMemo(
          () =>
            showMarker && (
              <InfoWindow onCloseClick={handleToggleMarker}>
                <div style={{ color: tourColor }}>
                  <strong>{`${location.address}, ${location.postalCode}, ${location.city}`}</strong>
                  <br />
                  <p>{t('MOVE_TO_TOUR')}:</p>
                  <div style={{ display: 'flex', width: '100%' }}>
                    {tours.map(
                      (newTour) =>
                        tour.id !== newTour.id && (
                          <ButtonBase
                            key={newTour.id}
                            onClick={handleMoveSingle(tour, newTour, stop)}
                          >
                            <SvgMarker
                              style={{
                                color: newTour.color,
                                width: '32px',
                                height: '32px',
                              }}
                              markerNumber={newTour.number}
                              markerColor={`%23${newTour.color.substring(1)}`}
                            />
                          </ButtonBase>
                        ),
                    )}
                  </div>
                  {!isSingleTour && (
                    // eslint-disable-next-line
                    <div
                      className={styles['hide-tour-button']}
                      onClick={toggleTour}
                    >
                      <FontAwesomeIcon icon={faEye} /> {t('HIDE_TOUR')}
                    </div>
                  )}
                </div>
              </InfoWindow>
            ),
          [
            showMarker,
            handleToggleMarker,
            tourColor,
            location.address,
            location.postalCode,
            location.city,
            t,
            tours,
            isSingleTour,
            toggleTour,
            tour,
            handleMoveSingle,
            stop,
          ],
        )}
      </Marker>
    )
  },
)

export default GoogleMarker

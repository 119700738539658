import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    gridContainer: {
      height: 752,
      marginTop: '32px',
    },
    toursContainer: {
      maxHeight: 784,
      height: 784,
      overflowY: 'auto',
    },
    toolbar: {
      minHeight: 'auto',
      marginBottom: spacing(4),
    },
  })

export default makeStyles(styles)

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grow,
  Popover,
  Box,
  Avatar,
  ListItemSecondaryAction,
  Badge,
} from '@material-ui/core'
import clsx from 'clsx'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import toPairs from 'lodash/toPairs'
import defaultTo from 'lodash/defaultTo'

import { Subco } from 'common/models/typings/Subco'

import { shortenString } from 'utils/shortenString'
import { isDevelopment } from 'constants/index'

import { ReactComponent as Toggle } from 'common/assets/images/sidebar/toggle.svg'
import { ReactComponent as ToggleCollapsed } from 'common/assets/images/sidebar/toggle-collapsed.svg'

import languages from './resource'

import Icon from '../.raw/Icon'
import DropdownMenu from '../.raw/DropdownMenu'

import styles from './styles.module.scss'
import Typography from '../Typography'
import { useSelector } from 'react-redux'
import { getIsShipmentsFetching } from '../../../../selectors/ui'

export interface SidebarProps {
  shipmentCount: number
  hubInventoryCount: number
  subcontractor: Subco
  subcontractors: Subco[]
  subcontractorIndexes: any[]
  collapsed?: boolean
  currentLocale: string
  onSubcoClick: (ev: React.MouseEvent<any>) => void
  onToggleCollapsed: () => void
  onChangeSubco: (id: number) => void
  onLanguageChange: (lang: string) => void
  onLogoff: () => void
}

const Sidebar: React.FC<SidebarProps> = ({
  shipmentCount,
  hubInventoryCount,
  subcontractor,
  subcontractors,
  subcontractorIndexes,
  collapsed,
  currentLocale,
  onSubcoClick,
  onToggleCollapsed,
  onChangeSubco,
  onLanguageChange,
  onLogoff,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const isShipmentsFetching = useSelector(getIsShipmentsFetching)

  const handleToggleAnchor = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if (subcontractors.length < 2) return
      if (anchorEl) {
        setAnchorEl(null)
      } else {
        setAnchorEl(event.currentTarget)
      }
    },
    [anchorEl, subcontractors.length],
  )

  const handleToggle = React.useCallback(() => {
    onToggleCollapsed()
  }, [onToggleCollapsed])

  const handleNavigte = React.useCallback(
    (path: string) => {
      navigate(path)
    },
    [navigate],
  )

  const isPathActive = React.useCallback(
    (part: string) => {
      if (part === '/') {
        return pathname === part
      }
      return pathname.search(part) > -1
    },
    [pathname],
  )

  const handleClickSubco = React.useCallback(
    (value) => {
      onChangeSubco(value)
      setAnchorEl(null)
    },
    [onChangeSubco],
  )

  const subcontractorsInitials = get(subcontractor, 'name', '')
    .split(' ')
    .map((str: string) => str.substr(0, 1))
    .join('')
    .substr(0, 2)

  const subcontractorsList = React.useMemo(
    () =>
      reduce(
        subcontractors,
        (acc, prop) => {
          ;(acc as any)[subcontractorIndexes[prop.tag as any]] = shortenString(
            `${prop.tag} - ${prop.name}`,
            20,
          )
          return acc
        },
        {},
      ),
    [subcontractorIndexes, subcontractors],
  )

  const languageList = React.useMemo(
    () =>
      reduce(
        languages,
        (acc, prop) => {
          ;(acc as any)[prop.value] = (
            <li>
              <span className={styles['sidebar--profile-language']}>
                {prop.icon}
                <span>{prop.text}</span>
              </span>
            </li>
          )
          return acc
        },
        {},
      ),
    [],
  )

  return (
    <div
      className={clsx('no--print', styles.sidebar, {
        [styles['sidebar--collapsed']]: collapsed,
      })}
    >
      <div className={styles.content}>
        <ButtonBase
          type="button"
          className={styles.toggler}
          onClick={handleToggle}
        >
          {collapsed ? <ToggleCollapsed /> : <Toggle />}
        </ButtonBase>
        <div
          className={clsx(styles.logo, {
            [styles['logo--collapsed']]: collapsed,
          })}
        >
          <div />
        </div>
        <div className={styles['content-inner']}>
          <div className={styles.scrollable}>
            <List>
              <ListItem
                button
                className={clsx(styles['list-item'], {
                  [styles['list-item--active']]: isPathActive('/'),
                })}
                onClick={() => handleNavigte('/')}
              >
                <ListItemIcon>
                  <Icon name="package" />
                </ListItemIcon>
                <ListItemText primary={t('ALL_SHIPMENTS')} />
                <ListItemSecondaryAction style={{ paddingRight: 16 }}>
                  {isShipmentsFetching ? (
                    <Icon
                      className={styles['shipments-count-loader']}
                      name="spin-loader"
                    />
                  ) : (
                    <Badge
                      badgeContent={shipmentCount}
                      max={shipmentCount}
                      color="error"
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem
                button
                className={clsx(styles['list-item'], {
                  [styles['list-item--active']]: isPathActive('/planning'),
                })}
                onClick={() => handleNavigte('/planning')}
              >
                <ListItemIcon>
                  <Icon name="organisation" />
                </ListItemIcon>
                <ListItemText primary={t('LOCK_PLANNING_TITLE')} />
              </ListItem>
              {isDevelopment && (
                <ListItem
                  button
                  className={clsx(styles['list-item'], {
                    [styles['list-item--active']]: isPathActive('/collections'),
                  })}
                  onClick={() => handleNavigte('/collections')}
                >
                  <ListItemIcon>
                    <Icon name="package" />
                  </ListItemIcon>
                  <ListItemText primary={t('COLLECTIONS')} />
                </ListItem>
              )}
            </List>
          </div>
          <div>
            {subcontractor && (
              <Grow in={!isEmpty(subcontractor)} timeout={450}>
                <div>
                  <Box
                    display="flex"
                    alignItems="center"
                    paddingX={3}
                    className={clsx(styles.profile, {
                      [styles['profile--active']]: isPathActive('/profile'),
                    })}
                    onClick={onSubcoClick}
                  >
                    <Box
                      className={clsx(
                        styles['sidebar--profile-picture'],
                        styles.sidebarProfilePicture,
                      )}
                      marginRight={collapsed ? 0.5 : 2}
                      marginLeft={collapsed ? 0.5 : 0}
                      color="white"
                    >
                      {subcontractorsInitials}
                    </Box>
                    <Box flexGrow={1} display={collapsed ? 'none' : 'block'}>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={clsx({
                          [styles['profile-text']]: subcontractors.length > 1,
                        })}
                        onClick={
                          subcontractors.length > 1
                            ? handleToggleAnchor
                            : onSubcoClick
                        }
                      >
                        <Typography
                          color={
                            subcontractors.length > 1 ? 'inherit' : 'white'
                          }
                        >
                          {defaultTo(
                            shortenString(subcontractor.name, 15),
                            get(
                              subcontractorsList,
                              subcontractorIndexes[
                                subcontractor.tag as unknown as number
                              ],
                              '',
                            ),
                          )}
                        </Typography>
                        {subcontractors.length > 1 && (
                          <Icon
                            name="caret-down"
                            fill="currentColor"
                            style={{ marginLeft: 8 }}
                          />
                        )}
                      </Box>

                      <Typography variant="packageText" color="white">
                        {t('SUBCONTRACTOR')}
                      </Typography>
                    </Box>
                  </Box>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleToggleAnchor}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      className: styles['popper-paper'],
                    }}
                  >
                    <List disablePadding>
                      {sortBy(toPairs(subcontractorsList), '[1]').map(
                        (pair, i) => {
                          const keyValue = get(pair, '[0]')
                          const text = get(pair, '[1]')
                          return (
                            <ListItem
                              button
                              key={text}
                              onClick={() => handleClickSubco(keyValue)}
                            >
                              <ListItemText primary={text} />
                            </ListItem>
                          )
                        },
                      )}
                    </List>
                  </Popover>
                  <div
                    className={clsx(
                      styles['sidebar--profile-language-picker'],
                      styles.languagePicker,
                    )}
                  >
                    <DropdownMenu
                      data={languageList}
                      value={currentLocale}
                      onChange={onLanguageChange}
                    />
                  </div>
                </div>
              </Grow>
            )}
            <ul
              className={clsx(
                styles['sidebar--menu'],
                styles.sidebarMenu,
                styles.userIcon,
              )}
            >
              <li
                className={clsx({ active: isPathActive('/drivers') })}
                onClick={() => navigate('/drivers')}
              >
                <Icon name="user" fill="currentColor" />
                <span>{t('EMPLOYEES')}</span>
              </li>
              <li className={styles['sidebar--menu-alert']} onClick={onLogoff}>
                <Icon name="logout" fill="currentColor" />
                <span>{t('LOG_OFF')}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Sidebar)

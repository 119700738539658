/* eslint-disable import/prefer-default-export, no-bitwise */

export const contrastColor = (color: string) => {
  const c = color.substr(1)
  const rgb = parseInt(c, 16)
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = (rgb >> 0) & 0xff
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b
  if (luma > 200) {
    return '#201047'
  }
  return '#ffffff'
}

export const colours = [
  '#FF5351',
  '#FF0000',
  '#9D0000',
  '#CC7A55',
  '#AC4900',
  '#12D2FC',
  '#5977FF',
  '#303F9F',
  '#4BF1FF',
  '#1485CC',
  '#FFB333',
  '#FF8F5E',
  '#FFEA00',
  '#E39D00',
  '#FFCE00',
  '#29A389',
  '#00FFB1',
  '#00AD49',
  '#30929F',
  '#006704',
  '#201047',
  '#6900A0',
  '#E83F8A',
  '#FF55E6',
  '#484848',
  '#939393',
  '#DDDDDD',
]

export const getColor = (index: number) =>
  index
    ? colours[index % colours.length]
    : colours[Math.floor(Math.random() * colours.length) % colours.length]

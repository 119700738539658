import React, { useCallback, useEffect } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import moment from 'moment'

import { useSelector } from 'react-redux'
import { getCurrentSubcoId } from 'selectors/profile'
import { getNetworkType } from 'selectors/networkType'
// eslint-disable-next-line import/no-cycle
import { getShipmentsAtRegionalHub } from 'selectors/hubLogs'

import StartPlanning from 'common/components/features/EmptyState/StartPlanning'
import InventoryScanDialogWarning from 'common/components/features/InventoryScan/Dialog/Warning'
// eslint-disable-next-line import/no-cycle
import TimelineHorizontal from 'common/components/base/Timeline/Horizontal'
import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'

import useStyles from './useStyles'
// eslint-disable-next-line import/no-cycle
import NetworkTypeHeader from '../../features/NetworkTypeHeader'
import ChooseNetworkType from '../../../../pages/planningWizard/chooseNetworkType'
import { NETWORK_TYPE } from '../../../models/typings/TourOverview'
import { Callout } from '../../base/.raw'
import * as warehouseAction from '../../../../actions/warehouse'
import { useInterval } from '../../../../hooks'
import { capitalize } from '../../../../utils/capitalize'
import Typography from 'common/components/base/Typography'

const LayoutPlanningWizard = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const classes = useStyles()

  const currentSubcoId = useSelector(getCurrentSubcoId)
  const atRegionalHub = useSelector(getShipmentsAtRegionalHub)
  const networkType = useSelector(getNetworkType)

  const [isWelcomeShowed, setIsWelcomeShowed] = React.useState<boolean>(false)
  const [current, setCurrent] = React.useState<number>(0)
  const [isWarningOpen, setIsWarningOpen] = React.useState<boolean>(false)
  const [nextPage, setnextPage] = React.useState<number | undefined>(undefined)

  const checkNetworkStatus = useCallback(() => {
    warehouseAction.getNetworkStatus(moment().format('YYYY-MM-DD'), networkType)
  }, [networkType])

  const [startCheckingNetworkStatus, stopCheckingNetworkStatus] = useInterval(
    checkNetworkStatus,
    60000,
  )

  useEffect(() => {
    checkNetworkStatus()
    startCheckingNetworkStatus()
    return () => stopCheckingNetworkStatus()
  }, [
    checkNetworkStatus,
    startCheckingNetworkStatus,
    stopCheckingNetworkStatus,
  ])

  React.useEffect(() => {
    if (!isWelcomeShowed) {
      const isShowed = localStorage.getItem('wizardWelcome')
      if (isShowed !== null) {
        setIsWelcomeShowed(true)
      }
    }
  }, [isWelcomeShowed, pathname])

  const handleStartPlanning = React.useCallback(() => {
    localStorage.setItem('wizardWelcome', '1')
    setIsWelcomeShowed(true)
  }, [])

  const handleToggleWarning = React.useCallback(
    () => setIsWarningOpen((prev) => !prev),
    [],
  )

  const handleShowInventoryScanWarning = React.useCallback(() => {
    const currentD = moment()
    const currentHour = currentD.hour()
    let inventoryScanPageIsAllowed = false
    if (currentHour > 3 && currentHour < 15) {
      inventoryScanPageIsAllowed = true
    } else {
      inventoryScanPageIsAllowed = false
    }

    const itemFromStorage =
      localStorage.getItem('wizardInventoryScanWarning') || '[]'
    const parsedItemFromStorage: any[] = JSON.parse(itemFromStorage)
    const subcoData = parsedItemFromStorage.find(
      (itm: any) => itm.id === currentSubcoId,
    )
    const dateAcceptIsBefore =
      subcoData && moment(subcoData.date).isBefore(moment(), 'day')

    if (inventoryScanPageIsAllowed && atRegionalHub.length > 1) {
      if (!subcoData) {
        return true
      }
      if (dateAcceptIsBefore) {
        return true
      }
      return false
    }

    return false
  }, [atRegionalHub.length, currentSubcoId])

  const handleNavigateUsingTimeline = React.useCallback(
    (nxtPage: number, url: string) => {
      const isProceedBesideInventoryScanNotAllowed =
        handleShowInventoryScanWarning()

      // check if current page is Inventory Scan
      // then check if the nxtPage is not Inventory Scan
      if (
        current === 0 &&
        nxtPage !== 0 &&
        isProceedBesideInventoryScanNotAllowed
      ) {
        setnextPage(nxtPage)
        handleToggleWarning()
        return
      }

      navigate(url)
      setCurrent(nxtPage)
    },
    [current, handleShowInventoryScanWarning, handleToggleWarning, navigate],
  )

  const timeline = React.useMemo(
    () => [
      {
        title: t('PLANNING_TIMELINE.INVENTORY_SCAN'),
        description: t('PLANNING_TIMELINE.INVENTORY_SCAN_DESC'),
        path: '/planning/inventory-scan',
        onClick: () =>
          handleNavigateUsingTimeline(0, '/planning/inventory-scan'),
      },
      {
        title: t('PLANNING_TIMELINE.TOUR_PLANNING'),
        description: t('PLANNING_TIMELINE.TOUR_PLANNING_DESC_BY_NETWORK', {
          networkType: capitalize(t(`NETWORK_TYPE.${networkType}`)),
        }),
        path: '/planning/tour-planning',
        onClick: () =>
          handleNavigateUsingTimeline(1, '/planning/tour-planning'),
      },
      {
        title: t('PLANNING_TIMELINE.HANDOVER'),
        description: t('PLANNING_TIMELINE.HANDOVER_DESC'),
        path: '/planning/handover',
        onClick: () => handleNavigateUsingTimeline(2, '/planning/handover'),
      },
      {
        title: t('PLANNING_TIMELINE.MONITORING'),
        description: t('PLANNING_TIMELINE.MONITORING_DESC'),
        path: '/planning/monitoring',
        onClick: () => handleNavigateUsingTimeline(3, '/planning/monitoring'),
      },
    ],
    [handleNavigateUsingTimeline, t],
  )

  React.useEffect(() => {
    const { path } = timeline[current]

    if (pathname.indexOf(path as string) === -1) {
      const index = timeline.findIndex(
        (tl) => pathname.indexOf(tl.path as string) > -1,
      )
      if (index !== -1) {
        setCurrent(index)
      }
    }
  }, [pathname, timeline, current, navigate])

  const handleWarningProceed = React.useCallback(() => {
    const currentD = moment().format()
    const itemFromStorage =
      localStorage.getItem('wizardInventoryScanWarning') || '[]'
    const parsedItemFromStorage = JSON.parse(itemFromStorage)
    if (Array.isArray(parsedItemFromStorage)) {
      const index = parsedItemFromStorage.findIndex(
        (itm) => itm.id === currentSubcoId,
      )

      if (index !== -1) {
        // eslint-disable-next-line no-underscore-dangle
        parsedItemFromStorage[index].date = currentD
      } else {
        parsedItemFromStorage.push({ id: currentSubcoId, date: currentD })
      }
    }

    localStorage.setItem(
      'wizardInventoryScanWarning',
      JSON.stringify(parsedItemFromStorage),
    )

    if (nextPage) {
      handleToggleWarning()
      navigate(timeline[nextPage].path as string)
      setCurrent(nextPage)
    }
  }, [currentSubcoId, handleToggleWarning, navigate, nextPage, timeline])

  const handleProceedTimeline = React.useCallback(() => {
    if (current > timeline.length - 1) return

    const isProceedBesideInventoryScanNotAllowed =
      handleShowInventoryScanWarning()
    if (current === 0 && isProceedBesideInventoryScanNotAllowed) {
      setnextPage(current + 1)
      handleToggleWarning()
      return
    }

    navigate(timeline[current + 1].path as string)
    setCurrent((prev) => prev + 1)
  }, [
    current,
    handleShowInventoryScanWarning,
    handleToggleWarning,
    navigate,
    timeline,
  ])

  React.useEffect(() => {
    if (networkType === NETWORK_TYPE.DAY) {
      handleNavigateUsingTimeline(1, '/planning/tour-planning')
    }
    if (networkType === NETWORK_TYPE.NIGHT) {
      handleNavigateUsingTimeline(0, '/planning/inventory-scan')
    }
  }, [networkType])

  if (!isWelcomeShowed) {
    return <ChooseNetworkType handleStartPlanning={handleStartPlanning} />
  }

  return (
    <div className={classes.root}>
      <Box className={classes.toolbar}>
        <NetworkTypeHeader networkType={networkType} usage="Planning" />
        <div className={classes.timeline}>
          <TimelineHorizontal
            steps={timeline}
            current={current}
            networkType={networkType}
          />
          <Box display="flex" className={classes.action}>
            {current < timeline.length - 1 && (
              <Button
                endIcon={<Icon name="arrow-right" />}
                onClick={handleProceedTimeline}
                className={classes.button}
              >
                <Typography className={classes.buttonLabel}>
                  {current === 0
                    ? t('CREATE_TOURS_BY_NETWORK_2', {
                        networkType: t(`NETWORK_TYPE.${networkType}`),
                      })
                    : t('PROCEED_TO', {
                        location: timeline[current + 1].title,
                      })}
                </Typography>
              </Button>
            )}
          </Box>
        </div>
      </Box>
      <InventoryScanDialogWarning
        open={isWarningOpen}
        onClose={handleToggleWarning}
        onProceed={handleWarningProceed}
        title={t('PLANNING_TIMELINE.INVENTORY_SCAN_WARNING_TITLE')}
        content={t('RETURNS_SCAN.CAUTION_PROCEED_NEXT')}
        backButton={t('RETURNS_SCAN.ACKNOWLEDGEMENT.BACK')}
        proceedButton={t('RETURNS_SCAN.CAUTION_PROCEED_TO_OTHER_STEP')}
      />
      <Outlet />
    </div>
  )
}

export default LayoutPlanningWizard

/* eslint-disable space-before-blocks */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */
import * as types from '../../constants/ActionTypes'

import { getVrpOptions } from '../../selectors/ui'
import * as fromProfile from '../../selectors/profile'
import * as fromShipments from '../../selectors/shipments'
import * as fromWarehouse from '../../selectors/warehouse'
import * as fromNetworkType from '../../selectors/networkType'

import * as uiActions from '../ui'
import * as tourActions from '../tours'

import trunkrs from 'common/utils/Sdk'
import { leftFindJoin } from '../../utils/dataSets'

import { combineEstimations, createMultiColliStopMapping } from './helpers'
import { queueTask } from '../intervalTasks'
import moment from 'moment'
import { concatBermudaStops } from './helpers'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/typings/types/Shipment'
import {NETWORK_TYPE} from "../../common/models/typings/TourOverview";
import {useSelector} from "react-redux";
import { defaultHandlingTime } from '../../utils/estimated-time-calc'

export const add = (payload) => (dispatch) => {
  dispatch({ type: types.ADD_SHIPMENT, payload })
  dispatch(tourActions.setShipmentTours(payload))
}

export function edit(payload) {
  return { type: types.EDIT_SHIPMENT, payload }
}

export function del(payload) {
  return { type: types.DELETE_SHIPMENT, payload }
}

export function clear() {
  return { type: types.CLEAR_SHIPMENTS }
}

export function isSorted(payload) {
  return { type: types.SHIPMENT_SORTED, payload }
}

export const reloadShipments = () => async (dispatch, getState) => {
  dispatch(uiActions.setLoading(true, undefined,true))

  const networkType = fromNetworkType.getNetworkType(getState())
  const subco = fromProfile.getSdkSubco(getState())
  const { shipments } = await subco.fetchDailyShipmentOverview(`?filter=none&network=${networkType}`)

  dispatch(add(shipments))
  dispatch(uiActions.setLoading(false,undefined,false))
}

export const handOverShipment = (id) => async (dispatch, getState) => {
  const subcoId = fromProfile.getCurrentSubcoId(getState())
  dispatch(
    queueTask(`handover-${id}`, 'Shipment().sortToCartByShipmentId', [
      id,
      subcoId,
    ]),
  )
  dispatch({ type: types.SHIPMENT_IN_TRANSACTION, payload: { id } })
}

export const assignToTours = () => async (dispatch, getState) => {
  try {
    const timeStarted = moment()
    // dispatch(uiActions.setLoading(true, translate('OPTIMIZING')))
    dispatch(uiActions.setOptimizing(true))

    const state = getState()
    const departureTime = fromProfile.getDepartureTime(state)
    const subco = fromProfile.getSdkSubco(state)

    // verify if its busy day and central sorting is done
    // then only SHIPMENT_STATES.SHIPMENT_SORTED as shipments when creating new tours
    const { isBusyDay, isCentralSortingDone } = state.warehouse
    let shipments
    if (isBusyDay && isCentralSortingDone) {
      shipments = fromShipments
        .getShipments(state)
        .filter(
          ({ state: { name }, id }) =>
            name === SHIPMENT_STATES.SHIPMENT_SORTED && id > 0,
        )
    } else {
      shipments = fromShipments.getVRPShipments(state)
    }

    const [stops, mapping] = createMultiColliStopMapping(shipments)

    const vrpOptions = getVrpOptions(state)

    const toursArray = await trunkrs
      .Tour()
      .generateTourGroupings(stops, subco, vrpOptions)

    const hasVRPError = Array.isArray(toursArray)
      ? !!toursArray.find((tour) => !!tour.error)
      : !!(toursArray && !!toursArray.error)
    let hasTSPError = false

    if (Array.isArray(toursArray)) {
      const tours = toursArray.filter((tour) => !!tour.length)

      const tourShipments = tours.map((tour) =>
        leftFindJoin(
          tour,
          stops,
          (shipmentId) => shipmentId,
          (shipment) => shipment.id,
          (_, shipment) => shipment,
        ),
      )

      const result = await Promise.all(
        tourShipments.map((shipments) =>
          trunkrs
            .Tour()
            .optimizeGroupWithEstimations(shipments, subco, departureTime, defaultHandlingTime),
        ),
      )

      // Add bermuda stops to tour estimations
      const { tourEstimations, total } = concatBermudaStops(result)

      hasTSPError =
        tourEstimations.findIndex((tour) => !tour || (tour && tour.error)) > -1

      const expandedEstimations = combineEstimations(
        tourEstimations.filter((tour) => tour && !tour.error),
        mapping,
      )

      const overflowCount =
        tourEstimations.length - parseInt(vrpOptions.maxAmountOfVehicles)
      dispatch(tourActions.setTours(expandedEstimations, total))
      dispatch(
        uiActions.setOptimizeResponse({
          data: {
            elapsedTime: `${moment().diff(timeStarted, 'm')}m ${moment().diff(
              timeStarted,
              's',
            )}s`,
            overflowCount: overflowCount > 0 ? overflowCount : undefined,
            driverCount: vrpOptions.maxAmountOfVehicles,
          },
          isOptimizationError: !!hasTSPError,
          visible: true,
        }),
      )
    }

    dispatch(
      uiActions.set({
        optimizeModal: false,
        loading: false,
        optimizing: false,
      }),
    )

    dispatch(uiActions.setHasOptimizationError(hasVRPError || hasTSPError))
  } catch (error) {
    console.log(error)
    dispatch(
      uiActions.setOptimizeResponse({
        visible: true,
        isHttpError: true,
      }),
    )
    dispatch(
      uiActions.set({
        optimizeModal: false,
        optimizing: false,
        loading: false,
      }),
    )
  } finally {
    // dispatch(uiActions.setOptimizing(false))
  }
}

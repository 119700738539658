import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'

import get from 'lodash/get'

import { Tour } from 'common/models/typings/Tour'
import { Driver } from 'common/models/typings/Driver'

import { contrastColor, getColor } from 'common/utils/Color'

import Typography from 'common/components/base/Typography'
import Icon from 'common/components/base/Icon'
import Button from 'common/components/base/Button'

import useStyles from './useStyles'
import { Callout } from 'common/components/base/.raw'

export interface HandoverCartProps {
  /** for getting the cart color if not available in tour */
  index: number
  tour: Tour
  driver: Driver
  onHandover: (shipmentId: number) => void | Promise<void>
  onToggleChangeDriver: (tour: Tour) => void
}

const HandoverCart: React.FC<HandoverCartProps> = ({
  index,
  tour,
  driver,
  onHandover,
  onToggleChangeDriver,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isPressed, setIsPressed] = React.useState(false)

  React.useEffect(() => {
    const isTKeyPressed = (event: any) => {
      setIsPressed(event.keyCode === 84)
    }
    const releaseT = (event: any) => {
      setIsPressed(false)
    }
    document.addEventListener('keydown', isTKeyPressed, false)
    document.addEventListener('keyup', releaseT, false)
    return () => {
      document.removeEventListener('keydown', isTKeyPressed, false)
      document.removeEventListener('keyup', releaseT, false)
    }
  }, [])

  const handleClick = React.useCallback(
    (collationId: number) => {
      if (isPressed) {
        onHandover(collationId)
      }
    },
    [isPressed, onHandover],
  )

  const totalDistanceInKM = React.useMemo(
    () => (get(tour, 'totalDistance') / 1000).toFixed(1).concat('km'),
    [tour],
  )

  return (
    <Card className={classes.root}>
      <Box position="absolute" top="16px" right="16px">
        <Button
          color="white"
          onClick={() => onToggleChangeDriver(tour)}
          className={classes.changeDriverBtn}
        >
          <Icon name="edit-user" style={{ height: 16, width: 16 }} />
        </Button>
      </Box>
      <CardHeader
        disableTypography
        title={
          <Box display="flex">
            <Box
              className={classes.titleContainer}
              style={{
                backgroundColor: tour.color || getColor(index),
                color: contrastColor(tour.color),
              }}
            >
              <Typography
                display="inline"
                color="inherit"
                className={classes.title}
              >
                {t('CART')} {tour.number}
              </Typography>
              <Box className={classes.badge}>
                <Typography variant="badge" color="indigo">
                  {tour.collations.length}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
        className={classes.header}
      />
      <CardContent className={classes.content}>
        <Typography noWrap variant="textBold">
          Driver: {driver.name}
        </Typography>
        <Typography variant="badge" color="indigo">
          {t('TOTAL_DISTANCE')}: {totalDistanceInKM}
        </Typography>
        <Box height={24} />
        <Typography variant="tableHeader">Parcels</Typography>
        <Box className={classes.parcelsContainer}>
          {!tour.collations.length && (
            <Callout variant="success" className={classes.callout}>
              {t('ALL_SHIPMENTS_HANDOVERED')}
            </Callout>
          )}

          <List dense className={classes.list}>
            {tour.collations &&
              tour.collations.map((collation, cIndex) => (
                <ListItem
                  button
                  dense
                  disableGutters
                  onClick={() => handleClick(Number(collation.id))}
                  style={{
                    borderBottom:
                      cIndex < tour.collations.length - 1
                        ? '1px solid rgba(0, 0, 0, .12)'
                        : 'none',
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography className={classes.listItemText}>
                        {collation.recipient.location.address}
                      </Typography>
                    }
                    secondary={
                      <Typography className={classes.listItemText}>
                        {collation.recipient.location.postalCode}{' '}
                        {collation.recipient.location.city}
                      </Typography>
                    }
                  />
                  {collation.isWorking && (
                    <ListItemSecondaryAction>
                      <Tooltip
                        arrow
                        placement="right"
                        title={<>{t('PROCESSING')}</>}
                        classes={{ tooltip: classes.listItemText }}
                      >
                        <CircularProgress size={24} />
                      </Tooltip>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  )
}

export default HandoverCart

/* eslint-disable import/no-cycle */

import React from 'react'

import { isValidCollation } from 'actions/tours/helpers'
import { getSelectedCollations } from 'selectors/ui'
import { useSelector } from 'react-redux'
import { Tour } from 'common/models/typings/Tour'

import GoogleMarker from './GoogleMarker'

export interface MarkerProps {
  tour: Tour
  isSingleTour?: boolean
}

export const Marker: React.FC<MarkerProps> = ({ tour, isSingleTour }) => {
  const selectedMarkers = useSelector(getSelectedCollations)
  const stops = React.useMemo(
    () => tour.collations.filter(isValidCollation as any),
    [tour],
  )

  return (
    <>
      {stops.map((stop: any) => (
        <GoogleMarker
          tour={tour}
          tourColor={tour.color}
          stop={stop}
          isSelected={selectedMarkers.includes(stop.id)}
          isSingleTour={isSingleTour}
          key={stop.id}
        />
      ))}
    </>
  )
}

export default React.memo(Marker)

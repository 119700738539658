/* eslint-disable import/no-cycle */

import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'

import i18n from 'common/i18n'

import { useDispatch, useSelector } from 'react-redux'
import * as profileActions from 'actions/profile'
import * as driverActions from 'actions/drivers'
import * as shipmentActions from 'actions/shipments'
import * as uiActions from 'actions/ui'
import * as greenRegionActions from 'actions/greenRegions'
import { getNumberOfNonCancelledShipments } from 'selectors/shipments'
import {
  getHubInventory,
  isShipmentReturnedExistOnInventory,
} from 'selectors/hubLogs'
import * as fromProfile from 'selectors/profile'
import * as fromNetworkType from 'selectors/networkType'
import { getIsBatchHubOutDone } from 'selectors/combined'

import trunkrs, { responseHandler } from 'common/utils/Sdk'
import { invalidateAuth } from 'utils/invalidateAuth'

import Sidebar from 'common/components/base/Sidebar'
import DialogLoader from 'common/components/base/Dialog/Loader'

import styles from './styles.module.scss'
import ErrorBoundary from '../ErrorBoundary'
import { NETWORK_TYPE } from '../../../models/typings/TourOverview'

const LayoutDashboard: React.FC = ({ children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [collapsed, setCollapsed] = React.useState<boolean>(false)

  const {
    ui: { isAuthenticated, loading, bootstrapping, optimizing },
  } = useSelector((state: any) => state)
  const shipmentCount = useSelector(getNumberOfNonCancelledShipments)
  const subcontractor = useSelector(fromProfile.getSubco)
  const subcontractors = useSelector(fromProfile.getOrderedSubcos)
  const subcontractorIndexes = useSelector(fromProfile.getSubcoIndex)
  const selectedSubcoId = useSelector(fromProfile.getCurrentSubcoId)
  const currentLocale = useSelector(fromProfile.getCurrentLocale)
  const hubInventory = useSelector(getHubInventory)
  const isNotAcknowledgedYet = useSelector(isShipmentReturnedExistOnInventory)
  const isHubOutDone = useSelector(getIsBatchHubOutDone)
  const networkType: NETWORK_TYPE = useSelector(fromNetworkType.getNetworkType)

  const hubInventoryCount = React.useMemo(
    () =>
      !isNotAcknowledgedYet || isHubOutDone
        ? hubInventory.filter(
            (shipment) => shipment.type === 'KEEP_IN_REGION' || !shipment.type,
          ).length
        : hubInventory.length,
    [hubInventory, isNotAcknowledgedYet, isHubOutDone],
  )

  const handleInitialData = React.useCallback(async () => {
    dispatch(uiActions.setBootstrapping(true))
    dispatch(uiActions.setLoading(true))
    const [, result] = await responseHandler<any>(
      trunkrs.Auth().fetchSubcoForUser(),
    )

    if (result) {
      dispatch(profileActions.setSubco(result))
      const subcos = !Array.isArray(result) ? [result] : result
      const selectedSubco = subcos.find(
        (subco) => get(subco, 'getId') === selectedSubcoId,
      )
      const selectedSubcoIndex = Math.max(
        subcos.findIndex((subco) => get(subco, 'getId') === selectedSubcoId),
        0,
      )

      const [, driverInstances] = await responseHandler(
        selectedSubco
          ? get(selectedSubco, 'getDrivers')
          : get(subcos, '[0].getDrivers'),
      )

      if (driverInstances) {
        const [, driversResult] = await responseHandler(
          Promise.all(driverInstances as any),
        )

        const drivers = defaultTo(driversResult, []).map((driver) => ({
          id: get(driver, 'id'),
          name: get(driver, 'name'),
          email: get(driver, 'getEmailAddress'),
          phoneNumber: get(driver, 'getPhoneNumber'),
          active: get(driver, 'active'),
          isPlanner: get(driver, 'isPlanner'),
          isArchived: get(driver, 'isArchived'),
          picture: get(driver, 'picture'),
          Instance: driver,
        }))

        const [, location] = await responseHandler(
          selectedSubco
            ? get(selectedSubco, 'getLocation')
            : get(subcos, '[0].getLocation'),
        )
        const [, accesstoken] = await responseHandler(
          trunkrs.Auth().getValue('accessToken' as unknown as any),
        )

        dispatch(
          profileActions.login({
            id: selectedSubco
              ? get(selectedSubco, 'getId')
              : get(subcos, '[0].getId'),
            accesstoken,
            subcos,
            selectedSubco: selectedSubcoIndex,
            drivers,
            location,
          }),
        )
        dispatch(profileActions.setRoles())
        dispatch(profileActions.fetchLocale())
      }

      const {
        shipments,
        subcontractor: { drivers },
      } = await result[selectedSubcoIndex].fetchDailyShipmentOverview(
        `?filter=none&networkType=${networkType}`,
      )
      dispatch(driverActions.addMultiple(drivers))
      dispatch(shipmentActions.add(shipments))

      try {
        const greenRegions = await trunkrs.GreenRegion().fetchAll()
        if (greenRegions.length) {
          dispatch(greenRegionActions.addMultiple(greenRegions))
        }
      } catch (error) {
        console.error('Failed to fetch green regions')
      }
    }

    dispatch(uiActions.setLoading(false))
    dispatch(uiActions.setBootstrapping(false))
  }, [dispatch])

  React.useEffect(() => {
    handleInitialData()
  }, [handleInitialData])

  React.useEffect(() => {
    i18n.changeLanguage(currentLocale)
  }, [currentLocale])

  React.useEffect(() => {
    if (!isAuthenticated) navigate('/login')
  }, [isAuthenticated, navigate])

  const handleToggleCollapsed = React.useCallback(
    () => setCollapsed((prev) => !prev),
    [],
  )

  const handleSubcoClick = React.useCallback(
    (ev: React.MouseEvent<any>) => {
      ev.stopPropagation()
      navigate('/profile')
    },
    [navigate],
  )

  const handleChangeSubco = React.useCallback(
    (id: number) => dispatch(profileActions.changeSubco(id)),
    [dispatch],
  )

  const handleLanguageChange = React.useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang)
      dispatch(profileActions.setLocale(lang))
    },
    [dispatch],
  )

  const handleLogoff = React.useCallback(async () => {
    await invalidateAuth()
    const loggedIn = await trunkrs.Auth().checkAccess()
    dispatch(uiActions.setIsAuthenticated(loggedIn))
    navigate('/login')
  }, [dispatch, navigate])

  return (
    <ErrorBoundary>
      <div className={styles.dashboard}>
        <Sidebar
          shipmentCount={shipmentCount}
          hubInventoryCount={hubInventoryCount}
          subcontractor={subcontractor}
          subcontractors={subcontractors}
          subcontractorIndexes={subcontractorIndexes}
          collapsed={collapsed}
          currentLocale={currentLocale}
          onSubcoClick={handleSubcoClick}
          onToggleCollapsed={handleToggleCollapsed}
          onChangeSubco={handleChangeSubco}
          onLanguageChange={handleLanguageChange}
          onLogoff={handleLogoff}
        />
        <div
          className={clsx(styles.content, {
            [styles['content--collapsed']]: collapsed,
          })}
        >
          <Outlet />
        </div>
      </div>
      <DialogLoader open={loading || bootstrapping} />
    </ErrorBoundary>
  )
}

export default React.memo(LayoutDashboard)

import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export interface ISvgMarkerProps extends SvgIconProps {
  markerNumber?: number
  markerColor?: string
}

const SvgMarker: React.FC<Exclude<ISvgMarkerProps, 'viewBox'>> =
  React.forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      width="60"
      height="80"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#220C4A"
      stroke-width="1"
      {...props}
    >
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5 7 13 7 13s7-8 7-13c0-3.87-3.13-7-7-7z" />
      <text
        x="12"
        y="10"
        textAnchor="middle"
        fill="white"
        fontSize="14px"
        fontWeight="bold"
        stroke="none"
        dy=".4em"
      >
        {props.markerNumber}
      </text>
    </SvgIcon>
  ))

export default SvgMarker
